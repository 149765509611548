.card-jucy {
    height: 100%;
}

.deals-content {
    @include font-size($font-size-base * 0.875);

    color: $text-muted;

    strong {
        color: $body-color;
        font-weight: $font-weight-ultra;
    }
}

.sticker {
    &.sticker-deal {
        left: spacer(3);
        position: absolute;
        top: spacer(3);
    }
}
