.dropdown-item.active,
.dropdown-item:active {
    background: transparent;
    color: $body-color;
    font-weight: bold;

    .fa-check {
        font-size: 12px;
        margin-top: 6px;
    }
}
