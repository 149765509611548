@import './includes';

$ig-blue: var(--secondary-color);

@import 'react-image-gallery/styles/scss/image-gallery';

.item-frame {
  width: 100%;
  height: 480px;
  border: none;
}

.item-image {
  width: 100%;
  height: auto;
}
