@use "sass:math";

@mixin loader-dots($size: 17px, $spacing: 5px, $delay: 0.7) {
    position: relative;
    margin: 0 ($size + ($spacing * 2));
    text-indent: -9999em;
    &,
    &::before,
    &::after {
        display: inline-block;
        width: $size;
        height: $size;
        border-radius: 50%;
        animation-duration: ($delay * 2.5) + s;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }
    &::before,
    &::after {
        position: absolute;
        top: 0;
        content: '';
    }
    &::before {
        left: -($size + $spacing);
        animation-delay: ($delay * 0.5) + s;
    }
    & {
        animation-delay: ($delay * 1) + s;
    }
    &::after {
        left: ($size + $spacing);
        animation-delay: ($delay * 1.5) + s;
    }
}

@mixin loader-dots-color($variant) {
    $animation-name: loader-dots-#{$variant};
    $background-color: map-get($theme-colors, $variant);
    &,
    &::before,
    &::after {
        background-color: rgba($background-color, 0.4);
        animation-name: $animation-name;
    }
}

@mixin hourglass($height: 36px) {
    display: inline-block;
    animation: hour-glass-rotate 5s ease-in-out infinite;
    i {
        position: relative;
        z-index: 2;
        font-size: $height;
    }
    .cup {
        position: absolute;
        right: 2px;
        left: 2px;
        z-index: 1;
        height: $height*0.5;
        overflow: hidden;
        border-radius: 50% 50% 0 0/ 100% 100% 0 0;

        &.top {
            top: 1px;
            transform: rotate(180deg);
        }

        &.bottom {
            bottom: 1px;

            &::before {
                animation-delay: 0s;
            }
        }

        &::before {
            display: block;
            width: 150%;
            height: 150%;
            content: ' ';
            transform-origin: 0% 0%;
            animation: hour-glass-sand 5s linear infinite -2.5s;
        }
    }
}

@mixin hourglass-color($border-color: $primary, $sand-color: lighten($primary, 20)) {
    i {
        color: $border-color;
    }
    .cup {
        &::before {
            background: $sand-color;
        }
    }
}

@mixin spinner-loader($inactive: $lime, $active: $primary, $speed: 1.2s, $size: 50px, $unit: math.div($size, 16)) {
    display: inline-block;
    width: $size;
    height: $size;
    border: $unit solid $inactive;
    border-radius: 50%;
    animation: spinner $speed linear infinite;

    /* Dots */
    &.double-dot-in,
    &.dot-in,
    &.double-dot-stick,
    &.double-dot-out,
    &.dot-stick,
    &.dot-out {
        position: relative;

        &::before,
        &::after {
            position: absolute;
            top: ($unit * 2);
            left: ($size*0.5 - $unit);
            width: ($unit * 2);
            height: ($unit * 2);
            content: '';
            background: $active;
            border-radius: 50%;
        }

        &::after {
            top: auto;
            bottom: ($unit * 2);
        }
    }
    &.dot-in {
        &::after {
            display: none;
        }
    }
    &.double-dot-stick,
    &.dot-stick {
        &::before,
        &::after {
            top: (-$unit * 2);
        }

        &::after {
            top: auto;
            bottom: (-$unit * 2);
        }
    }
    &.dot-stick {
        &::after {
            display: none;
        }
    }
    &.double-dot-out,
    &.dot-out {
        &::before,
        &::after {
            top: (-$unit * 4);
        }

        &::after {
            top: auto;
            bottom: (-$unit * 4);
        }
    }
    &.dot-out {
        &::after {
            display: none;
        }
    }

    /* Waves */

    &.wave-out {
        box-shadow: (-$unit*0.5) (-$unit*0.5) 0 ($unit*0.5) $active;
    }
    &.wave-in {
        box-shadow: inset (-$unit*0.5) (-$unit*0.5) 0 ($unit*0.5) $active;
    }
    &.double-wave-out {
        box-shadow: (-$unit*0.5) (-$unit*0.5) 0 ($unit*0.5) $active, ($unit*0.5) ($unit*0.5) 0 ($unit*0.5) $active;
    }
    &.double-wave-in {
        box-shadow: inset (-$unit*0.5) (-$unit*0.5) 0 ($unit*0.5) $active, inset ($unit*0.5) ($unit*0.5) 0 ($unit*0.5) $active;
    }

    &.huge-wave-out {
        box-shadow: (-$unit*0.5) (-$unit*0.5) 0 ($unit * 2) $active;
    }
    &.huge-wave-in {
        box-shadow: inset (-$unit*0.5) (-$unit*0.5) 0 ($unit * 2) $active;
    }

    /* Sections */
    &.double-section,
    &.section,
    &.double-section-in,
    &.section-in,
    &.double-section-out,
    &.section-out,
    &.double-section-far,
    &.section-far {
        position: relative;

        &::before,
        &::after {
            position: absolute;
            top: -$unit;
            left: -$unit;
            display: block;
            width: $size;
            height: $size;
            content: '';
            border: $unit solid transparent;
            border-top-color: $active;
            border-radius: 50%;
        }

        &::after {
            border-top-color: transparent;
            border-bottom-color: $active;
        }
    }
    &.section {
        &::after {
            display: none;
        }
    }
    &.double-section-in,
    &.section-in {
        &::before,
        &::after {
            top: 0;
            left: 0;
            width: ($size - $unit * 2);
            height: ($size - $unit * 2);
        }
    }
    &.section-in {
        &::after {
            display: none;
        }
    }
    &.double-section-out,
    &.section-out {
        &::before,
        &::after {
            top: ($unit * -2);
            left: ($unit * -2);
            width: ($size + $unit * 2);
            height: ($size + $unit * 2);
        }
    }
    &.section-out {
        &::after {
            display: none;
        }
    }
    &.double-section-far,
    &.section-far {
        &::before,
        &::after {
            top: ($unit * -3);
            left: ($unit * -3);
            width: ($size + $unit * 4);
            height: ($size + $unit * 4);
        }
    }
    &.section-far {
        @extend .double-section-far;

        &::after {
            display: none;
        }
    }
}
