.social-links {
    @include media-breakpoint-up(md) {
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }

    .title {
        @include media-breakpoint-down(md) {
            @include font-size($h5-font-size);

            font-family: $apex-rounded;
            font-size: 0.875rem;
            font-weight: $font-weight-ultra;
        }

        @include media-breakpoint-up(md) {
            display: inline;
            font-size: $h3-font-size;
            font-weight: $font-weight-heavy;
            margin-right: ($spacer * 1.5);
        }
    }

    @include media-breakpoint-down(md) {
        .links {
            display: flex;
            justify-content: space-around;
        }
    }

    .btn.social-link {
        align-items: center;
        color: $secondary;
        display: inline-flex;
        font-size: 1.6875rem;
        justify-content: center;

        @include media-breakpoint-up(md) {
            &:not(:last-child) {
                margin-right: $spacer;
            }
        }
    }
}
