$font-family-sans-serif: var(--font-poppins), 'Verdana', sans-serif;

$green: #85c430;
$purple: #860171;
$purple-dark: #4e2847;
$orange: #fb5a02;
$charcoal: #111;
$lime: #cbe722;
$cobalt-blue: #0e2038;
$sky-blue: #223a5c;
$sea-blue: #768d9b;
$sand: #fbf6ef;
$rock: #c5d2db;
$shell: #e9ecf0;

$primary: var(--primary-color);
$secondary: var(--secondary-color);
$secondary-dark: $purple-dark;

$theme-colors: (
        'green': $green,
        'purple': $purple,
        'purple-dark': $purple-dark,
        'orange': $orange,
        'charcoal': $charcoal,
        'lime': $lime,
        'cobalt-blue': $cobalt-blue,
        'sky-blue': $sky-blue,
        'sea-blue': $sea-blue,
        'sand': $sand,
        'rock': $rock,
        'shell': $shell,
);
$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: (
                $spacer * 0.25,
        ),
  // 4
        2:
                (
                        $spacer * 0.5,
                ),
  // 8
        3: $spacer,
  // 16
        4:
                (
                        $spacer * 1.5,
                ),
  // 24
        5:
                (
                        $spacer * 2,
                ),
  // 32
        6:
                (
                        $spacer * 3,
                ),
  // 48
        7:
                (
                        $spacer * 4,
                ),
);
$gray-100: #f7f9fa;
$gray-200: #e9ecef;
$gray-300: #ebeff1;
$gray-400: #d1dbdf;
$gray-600: #8c989d;
$gray-800: #636e73;
$dropdown-link-active-bg: $secondary;
$font-weight-bolder: 700;
$default-drop-color: #3c04341a;
$default-drop-shadow: 0 0.125rem 1.125rem $default-drop-color;
$secondary-drop-shadow: 0 0 1rem -0.3rem rgba(50, 50, 50, 0.75);
$default-transition-speed: 0.35s;
$default-transition-timing-function: ease;
$overlay-gradient: transparent linear-gradient(1deg, #1116 0%, #1e1e1e66 25%, #60606000 100%) 0 0 no-repeat padding-box;

$apex-rounded-book: 'Apex Rounded Book', $font-family-sans-serif;
$apex-rounded: 'Apex Rounded', $font-family-sans-serif;
$font-weight-heavy: 800;
$font-weight-ultra: 900;

$site-border-width: 0.4375rem;
$site-header-height: 5rem;
$site-header-height-sm: 3.125rem;
$site-navbar-height: 5rem;
$mobile-nav-collapse: 767.98px;
$top-nav-height: 2.5rem;
$navbar-brand-height: 2.375rem;
$navbar-brand-width: 4.625rem;

$border-radius: 3px;

$dropdown-border-width: 2px;
$dropdown-box-shadow: $default-drop-shadow;
$dropdown-divider-bg: $gray-400;

$font-size-base: 1rem;
$smaller-font-size: 0.875rem;
$small-font-size: 0.75rem;
$base-font-weight: 300;
$h1-font-size: 0.875rem * 3;
$h2-font-size: 0.875rem * 1.875;
$h5-font-size: 0.875rem * 1.375;
$h6-font-size: 0.875rem * 1.125;
$dropdown-font-size: $smaller-font-size;

$text-muted: $gray-800;
$body-color: $charcoal;

$font-size-sm: 0.75rem;
$font-size-lg: 1.125rem;

$sticker-width: 4rem;
$sticker-height: 4rem;
$btn-font-weight: bold;
$btn-border-width: 0.125rem;
$btn-box-shadow: 0 0 transparent;
$btn-border-radius: 0.1875rem;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$border-width: 0.0625rem;

$input-btn-font-size: 0.875rem;
$input-btn-padding-y: 0.21875rem;
$input-btn-padding-x: 2rem;
$input-btn-padding-y-lg: 1.03125rem;
$input-btn-padding-x-lg: $input-btn-padding-y-lg * 3;
$input-box-shadow: 0 0 transparent;
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-font-family: $font-family-sans-serif;
$input-font-size: 0.875rem;
$input-font-weight: 300;
$input-line-height: 1.75;
$custom-control-indicator-size-lg: 1.5rem;
$custom-control-indicator-checked-disabled-bg: $gray-200;

$nav-tabs-border-color: $gray-300;
$nav-tabs-border-width: 0.0625rem;
$nav-tabs-border-radius: 0;

//$nav-tabs-link-hover-border-color: $secondary;
$nav-tabs-link-active-color: $body-color;
//$nav-tabs-link-active-border-color: $secondary;
$nav-link-disabled-color: $gray-400;
//$link-color: $secondary;
$nav-pills-link-active-bg: $gray-100;
$nav-pills-link-active-color: $body-color;

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: true;
$enable-validation-icons: true;
$enable-deprecation-messages: true;

$card-border-width: 0;
$card-border-color: transparent;

$float-label-input-height: 4rem;
$float-label-input-padding-x: $input-padding-x;
$float-label-input-padding-y: 1.125rem;
$float-label-input-padding-active-y: 0.8125;
$float-label-padding: $float-label-input-padding-y $float-label-input-padding-x;

$card-inner-border-radius: $border-radius;

$slick-nav-y: 8.75rem;
$slick-nav-x: -0.5rem;

$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: #333;
$breadcrumb-divider: quote('>');
$breadcrumb-border-radius: 0;

$form-group-margin-bottom: 1.625rem;
$form-feedback-invalid-color: $orange;
$form-feedback-invalid-color: $orange;
$font-weight-normal: 300;
$icon-width: 34.86px;

$table-variants: (
        "primary": var(--primary),
        "secondary": var(--secondary),
        "success":var(--success),
        "info":var(--info),
        "warning":var(--warning),
        "danger":var(--danger),
        "light":var(--light),
        "dark":var(--dark),
);
$input-btn-focus-color:  rgba(var(--primary), .25);
$input-focus-border-color: rgba(var(--primary), .25);
$form-range-thumb-active-bg: $primary;
$form-file-button-hover-bg: $purple;
//$accordion-button-bg:                     var(--#{$prefix}accordion-bg) !default;
$accordion-button-active-bg:          $primary;
$accordion-button-active-color:          $primary;
//$input-box-shadow
//$input-focus-box-shadow;

@import 'bootstrap/scss/functions';

$link-hover-color:              $primary;

@import 'bootstrap/scss/variables';

$font-family-sans-serif: var(--font-poppins), 'Verdana', sans-serif;
@debug "font family: #{$font-family-sans-serif}";
