.jucy-dropdown {
    .menu-toggle {
        color: $body-color;
        cursor: pointer;
        user-select: none;

        .caret {
            font-size: 8px;
            transform: rotate(0deg);
            transition: transform $default-transition-speed $default-transition-timing-function;
            vertical-align: middle;
        }
    }

    a.menu-toggle:hover {
        text-decoration: none;
    }

    &.show {
        & > .menu-toggle {
            .caret {
                transform: rotate(180deg);
            }
        }

        & > .dropdown-menu {
            opacity: 1;
        }
    }
}
