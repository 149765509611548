@use 'sass:math';

html {
  height: 100%;
}

body {
  font-feature-settings: 'ss04';
  overflow-x: hidden;
  height: 100%;

  &::after {
    display: none;
    content: '{ "xs":"#{map-get($grid-breakpoints, "xs")}","sm":"#{map-get($grid-breakpoints, "sm")}","md":"#{map-get($grid-breakpoints, "md")}","lg":"#{map-get($grid-breakpoints, "lg")}","xl":"#{map-get($grid-breakpoints, "xl")}"}';
  }

  & > img {
    visibility: hidden;
    position: absolute;
  }
}

.app-container{
  width: 100%;
  height: 100%;
}

@include media-breakpoint-down(md) {
  .element > .nav.nav-tabs {
    padding-left: math.div($grid-gutter-width, 2);
    padding-right: math.div($grid-gutter-width, 2);
  }
}

.content {
  z-index: 1;
}

.height-large {
  height: 640px;
}

.height-medium {
  height: 332px;
}

.height-small {
  height: 248px;
}

@include media-breakpoint-down(sm) {
  .height-large {
    height: auto;
  }

  .height-small {
    height: 166px;
  }
}

.fleet-category-page {
  .background-block {
    margin-bottom: 10rem;
  }

  .show-case {
    .image-360-icon {
      position: absolute;
      height: 96px;
      width: 96px;
      font-size: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.grid {
  margin-bottom: -30px;

  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

/* ---- .grid-item ---- */
.grid-item {
  margin-bottom: 30px;

  img {
    display: block;
    width: 100%;
  }
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;

  > .col,
  > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}

.show-case {
  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.font-default {
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left;

  @include font-size($font-size-base);
}

.element.element-holder {
  margin-bottom: spacer(6);
}

.icon-block {
  .font-icon {
    font-size: 3rem;
  }

  .image-icon,
  .font-icon {
    margin-right: spacer(2);
  }
}

.image-gallery {
  .inner {
    position: relative;
  }

  .overlay {
    height: 64px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.jucy-sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: $site-header-height + $site-border-width;
    z-index: $zindex-sticky;
  }
}

.jucy-scrollama {
  &.active {
    .item {
      position: fixed;
      top: $site-header-height + $site-border-width;
      z-index: $zindex-sticky;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.accordion-toggle {
  display: block;
  border-bottom: 0.25rem solid $nav-tabs-link-active-border-color;
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  .caret {
    transform: rotate(0deg);
    transition: transform $default-transition-speed $default-transition-timing-function;
  }

  &.collapsed {
    border-bottom: 1px solid $gray-300;
    color: $gray-800;

    .caret {
      transform: rotate(180deg);
    }
  }
}

@include media-breakpoint-down(md) {
  .nav.nav-tabs.mobile-accordion {
    display: none;
  }

  .tab-content.mobile-accordion .tab-pane {
    display: block;
    opacity: 1;
  }
}

@include media-breakpoint-up(md) {
  .mobile-accordion .accordion-toggle {
    display: none;
  }

  .tab-content.mobile-accordion .collapse {
    display: block;
  }
}

img {
  &.lazy {
    transition: opacity $default-transition-speed $default-transition-timing-function;
    opacity: 1;

    &.loading {
      opacity: 0 !important;
    }
  }
}

.blog-paginated-list {
  .card-body > .title:first-child {
    margin-top: 1.9425rem;
  }
}

.mb-col {
  margin-bottom: $grid-gutter-width;
}

.jucy-aspect-1 {
  width: 100%;
  height: auto;

  &::before {
    content: '';
    display: block;
    padding-top: 63.14%;
  }
}
