.sticker {
    //@extend .alert;

    align-items: center;
    background-color: $secondary;
    border-color: $secondary;
    color: $white;
    display: flex;
    flex-direction: column;
    font-family: $apex-rounded;
    font-size: $smaller-font-size;
    font-style: italic;
    font-weight: bolder;
    height: $sticker-height;
    justify-content: center;
    line-height: 1;
    padding: 0;
    text-align: center;
    //text-transform: uppercase;
    width: $sticker-width;

    p {
        margin-bottom: 0;
        margin-top: 1px;
    }

    > strong,
    p > strong {
        display: block;
        font-size: $h4-font-size;
        line-height: 0.9;
    }
}
