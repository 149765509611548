.overlay {
    align-items: center;
    background: transparent linear-gradient(1deg, #1116 0%, #1e1e1e66 25%, #60606000 100%) 0 0 no-repeat padding-box;
    display: flex;
    justify-content: center;
    text-align: center;

    > .overlay-text {
        color: $white;
        font-size: 1.125rem;
        font-weight: $font-weight-ultra;
        margin: auto;
    }
}
