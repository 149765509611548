@use "sass:math";
@use "sass:color";
@import "./includes";
.summary-layout {
    flex: 1 1;
}
.review-quote-page {
    .page-title {
        display: flex;
        align-items: center;

        .btn {
            padding: 0;
            margin: 0 0 0 auto;
        }
    }
}

.docs-example + h3 {
  margin-top: 23px;
  margin-bottom: 0;
}

.docs-example + h3 + hr {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}

.docs-example {
  position: relative;
  padding: 3em 1em 1em;
  border: 1px solid #d8d8d8;
  margin: 1.5rem 0 0;
}

.docs-example::before {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1em;
  color: #aaa;
  font-size: smaller;
  font-weight: 500;
  content: 'EXAMPLE';
}

.docs-example::after {
  clear: both;
  content: ' ';
  display: table;
}

.docs-example.icons {
  display: flex;
  flex-wrap: wrap;
  text-align: center;

  .icon-container {
    flex-basis: 15%;
    flex-grow: 0;
    padding: 5px 2px;
    margin: 5px;
    border: $card-border-width solid $card-border-color;

    border-radius: $card-border-radius;
  }

  i {
    font-size: 32px;
  }
}

.jucy-nav-tabs {
    @include media-breakpoint-down(md) {
        display: none;
    }

    .nav-link {
        cursor: pointer;
    }
}

.fade-enter,
.fade-leave.fade-leave-active {
    opacity: 0;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity $default-transition-speed $default-transition-timing-function;
}

.fade-appear {
    transition: opacity $default-transition-speed $default-transition-timing-function;
    opacity: 0.01;
}

.fade-leave {
    transition: opacity $default-transition-speed $default-transition-timing-function;
}
.group-info {
    .fa-info-circle {
        margin-right: 10px;
        margin-top: 4px;
    }

    background: #f7f7f7;
}

.booking-summary {
    //padding-left: 0;
    //padding-right: 0;
    //
    //@include media-breakpoint-up(md) {
    //    padding-top: 1.7rem;
    //    padding-left: 1.5rem;
    //    padding-right: $grid-gutter-width*0.5;
    //}

    & > .list-group {
        .list-group-item {
            margin-bottom: 4px;
            border: 0;
            border-radius: 0;
            background: none;
        }
    }

    .trip-info .separator {
        margin-top: 1.8rem;
    }
}

$type-btn-height: 80px;
$type-btn-width: 140px;

.icon-link {
    height: $type-btn-height;
    width: $type-btn-width;

    &.btn.btn-primary {
        $type-btn-height: 80px;
        $type-btn-width: 140px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    i {
        font-size: 60px;
        height: 47px;
        display: block;
        line-height: 1;
        margin-top: -10px;
    }

    &:not(.disabled) {
        &:hover,
        &:active,
        &:focus {
            background: $primary;
            color: #fff;
        }
    }

    &.disabled {
        background-color: $input-disabled-bg;

        &,
        .label,
        i {
            color: $input-color;
        }
    }
}

.jucy-date-range-select {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal-backdrop;
    background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);

    .DayPicker {
        @include border-radius($dropdown-border-radius);
        @include box-shadow($dropdown-box-shadow);
    }

    &.vertical {
        .DayPicker {
            padding-bottom: 52px;
        }
    }

    .DayPickerNavigation {
        display: flex;
        justify-content: space-between;
        padding: 9px;
        position: absolute;
        width: 100%;

        &__vertical {
            background-color: $white;
            bottom: 0;
        }

        &__verticalDefault {
            height: auto;
        }

        .nav-btn {
            color: $white;
            background: $secondary;
        }
    }

    .DateRangePicker_info {
        padding-right: 10px;
    }

    .CalendarDay {
        border: 0;
        color: $body-color;
        transition: border-radius $default-transition-speed $default-transition-timing-function,
        background-color $default-transition-speed $default-transition-timing-function;

        &__blocked_minimum_nights {
            position: relative;

            &:not(.CalendarDay__selected_start) {
                &::after {
                    bottom: 9px;
                    content: '\00b7';
                    display: block;
                    font-size: 30px;
                    left: 0;
                    line-height: 0;
                    position: absolute;
                    right: 0;
                    text-align: center;
                }
            }
        }

        &__selected {
            font-weight: bold;
        }

        &__selected_span,
        &__hovered_span {
            background-color: $gray-400;
            color: $secondary;
            font-weight: bold;
        }

        &:hover {
            background-color: $gray-200;
            color: $secondary;
        }

        &__blocked_out_of_range,
        &__blocked_minimum_nights {
            &,
            &:hover {
                background: $list-group-disabled-bg;
                color: $list-group-disabled-color;
            }
        }

        &__selected_start {
            background-color: $primary;

            &,
            &:hover {
                color: $secondary;
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
            }

            &:hover {
                //background-color: lighten($primary, 10%);
            }
        }

        &__selected_end {
            background-color: $secondary;

            &,
            &:hover {
                color: #fff;
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
            }

            &:hover {
                //background-color: lighten($secondary, 10%);
            }
        }
    }
}

.date-field {
    &.form-control {
        &[readonly] {
            color: $input-color;
            background-color: $input-bg;

            &:disabled {
                background-color: $input-disabled-bg;
            }
        }

        &.has-focus {
            @include focusedFormField();
        }

        &::placeholder {
            color: $input-color;
        }
    }
}
.payment-field {
    position: relative;
    width: 100%;

    .payment-frame {
        position: absolute;
        left: 0;
        top: 0;
        border: 0;
        width: 100%;
        height: 770px;
        overflow: hidden;
        opacity: 1;
        z-index: 0;
        transition: all 0.35s ease;
    }

    .loader {
        display: none;
        padding-top: 16px;
    }

    &.loading {
        .payment-frame {
            opacity: 0;
        }

        .loader {
            display: block;
        }
    }
}
.jucy-custom-control {
    &.is-invalid ~ .invalid-feedback {
        display: block;
    }
}

.time-select {
    .outside-range {
        border-right: 1px solid $gray-500;
    }

    .info-panel {
        padding: 8px 12px;
        border-top: 1px solid $gray-500;
    }
}

.form-control.jucy-select {
    box-sizing: border-box;
    padding: 0;
    position: relative;

    .Select:not(.is-open) {
        z-index: 0;
    }

    &--is-disabled {
        background-color: $input-disabled-bg;
        opacity: 1;
    }

    &.has-focus {
        @include focusedFormField();
    }

    .jucy-select {
        &:hover {
            opacity: 1;
        }

        &__control {
            display: flex;
            height: 100%;
            justify-content: space-between;
            position: relative;
            line-height: $input-line-height;

            @include border-radius($dropdown-border-radius);

            &--menu-is-open {
                .jucy-select__indicator {
                    transform: rotate(180deg);
                }
            }
        }

        &__value-container {
            padding: $input-padding-y $input-padding-x 0 $input-padding-x;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__single-value {
            position: absolute;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__placeholder {
            position: absolute;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__indicator {
            transform: rotate(0deg);
            transition: transform $default-transition-speed $default-transition-timing-function;
        }

        &__input {
            > input {
                //height: 0;
            }
        }

        &__menu {
            background-clip: padding-box;
            background-color: $dropdown-bg;
            border: $dropdown-border-width solid $dropdown-border-color;
            color: $dropdown-color;
            margin: $dropdown-spacer 0 0; // override default ul
            min-width: $dropdown-min-width;
            padding: $dropdown-padding-y 0;
            position: absolute;
            z-index: $zindex-dropdown;
            width: 100%;

            @include font-size($dropdown-font-size);
            @include border-radius($dropdown-border-radius);
            @include box-shadow($dropdown-box-shadow);
        }

        &__menu-list {
            max-height: 300px;
            overflow-y: auto;
            padding-bottom: 4px;
            padding-top: 4px;
            position: relative;
            -webkit-overflow-scrolling: touch;
            box-sizing: border-box;
        }

        &__option {
            color: $dropdown-link-color;
            cursor: pointer;
            display: block;
            padding: $dropdown-item-padding-y $dropdown-item-padding-x;

            // Prevent dropdown overflow if there's no padding
            // See https://github.com/twbs/bootstrap/pull/27703
            @if $dropdown-padding-y == 0 {
                &:first-child {
                    @include border-top-radius($dropdown-inner-border-radius);
                }

                &:last-child {
                    @include border-bottom-radius($dropdown-inner-border-radius);
                }
            }

            %hover-style {
                color: $dropdown-link-hover-color;
                text-decoration: none;

                @include gradient-bg($dropdown-link-hover-bg);
            }

            &:hover, &:focus {
                @extend %hover-style;
            }

            &--is-focused {
                color: $dropdown-link-hover-color;
                text-decoration: none;

                @include gradient-bg($dropdown-link-hover-bg);
            }

            &--is-selected {
                color: $dropdown-link-active-color;
                text-decoration: none;

                @include gradient-bg($dropdown-link-active-bg);
            }

            &--is-disabled {
                background-color: transparent;
                color: $dropdown-link-disabled-color;
                pointer-events: none;
                // Remove CSS gradients if they're enabled
                @if $enable-gradients {
                    background-image: none;
                }
            }
        }
    }
}

.paymentOptions {
    .jucy-custom-control.is-invalid ~ .invalid-feedback {
        display: none;
    }
}

.payment-details-modal {
    max-width: 615px;

    &.laybuy {
        .title {
            display: none;
        }
    }
}

.payment-options {
    &.cols-4 {
        .payment-option {
            &.deposit,
            &.full {
                .inner {
                    @include media-breakpoint-up(md) {
                        padding-top: 22px;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                .btn-link {
                    display: block;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}

.payment-form {
    .button-radio.form-control {
        max-width: 368px;
        margin: spacer(2) auto;
    }
}

.booking-widget {
  .MuiFormHelperText-root {
    position: absolute;
    top: calc(100% - 5px);
  }
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none;
    padding-right: 0 !important;
  }

  .fields.vertical {
    .jucy-date-range-select .DayPicker {
      left: 0;
      top: 100%;
    }
  }

  .fleet-category-container {
    display: flex;

    .form-control:not(:first-child) {
      .invalid-feedback {
        display: none;
      }
    }

    .image-radio {
      &:first-child {
        margin-right: spacer(3);
      }

      img {
        @media (max-width: 420px) {
          width: 38px;
          height: auto;
        }

        @media (max-width: 369px) {
          display: none;
        }
      }
    }
  }

  &.large,
  &.horizontal {
    min-width: 16rem;

    .form-group {
      align-items: flex-start;
      display: flex;

      .invalid-feedback {
        font-size: 11px;
        left: 12px;
        margin: 0;
        position: absolute;
      }

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
    }

    .pick-up-fields,
    .drop-off-fields {
      align-items: flex-start;
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      @include media-breakpoint-down(sm) {
        flex-basis: 100%;
      }

      .location-field {
        flex-basis: 100%;
        margin-bottom: $form-group-margin-bottom;
        width: 100%;

        @include media-breakpoint-down(sm) {
          margin-bottom: 0;

          > .form-control {
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      .form-label-group.date-field,
      .form-label-group.time-field {
        width: 50%;
      }

      .date-field > .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @include media-breakpoint-down(sm) {
          border-top-left-radius: 0;
        }
      }

      .time-field > .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        margin-left: -1px;

        @include media-breakpoint-down(sm) {
          border-top-right-radius: 0;
        }
      }
    }

    .separator {
      align-items: start;
      margin-top: ($float-label-input-height * 0.5) - 0.5;

      @include media-breakpoint-down(sm) {
        flex-basis: 100%;
        margin-bottom: math.div($float-label-input-height, 3) - 0.5;
        margin-top: ($float-label-input-height * 0.25) - 0.5;

        i {
          transform: rotate(90deg);
        }
      }
    }

    .footer {
      .actions {
        text-align: right;

        @include media-breakpoint-down(sm) {
          flex: 1 1 100%;
          margin-left: 0;

          .submit {
            height: auto;
            padding: $btn-padding-y $btn-padding-x;
            width: 100%;
          }
        }
      }
    }
  }

  &.horizonta1l {
    max-width: 49rem;

    > form > .fields {
      display: flex;
      flex-direction: row;

      & > .form-group {
        margin-bottom: 0;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 spacer(3);
      width: 168px;

      .actions,
      .fields {
        flex: 1 1 50%;
        width: 100%;
      }

      .fields {
        margin: 0;
        display: flex;
      }

      .actions {
        margin-top: spacer(4);
      }
    }
  }

  &.vertical {
    .pick-up-fields,
    .drop-off-fields {
      display: flex;
      flex-wrap: wrap;
    }

    .form-label-group {
      &.location-field {
        flex: 1 1 100%;

        .form-control {
          border-bottom-width: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &.date-field,
      &.time-field {
        flex: 0 0 50%;
        width: 50%;

        .form-control {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }

    .date-field {
      .form-control {
        border-right-width: 0;
        border-bottom-right-radius: 0;
      }
    }

    .time-field {
      .form-control {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .separator {
      margin-bottom: math.div($float-label-input-height, 3) - 0.5;
      margin-top: ($float-label-input-height * 0.25) - 0.5;
      width: 100%;

      i {
        transform: rotate(90deg);
      }
    }

    .footer {
      .actions {
        flex: 1 1 100%;
        margin-top: spacer(3);
        text-align: center;

        .submit {
          margin: auto;
        }
      }

      .fields {
        margin-right: 0;
      }
    }
  }

  .footer {
    margin-bottom: 0;

    .actions,
    .fields {
      flex: 1 1 50%;
    }

    .driver-age,
    .driver-age .promo-code-container {
      width: 50%;
    }

    .driver-age + .promo-code-container {
      .custom-control-label {
        margin-left: 10px;
      }
    }

    &.promo-visible {
      .driver-age {
        flex: 1 1 50%;

        & > .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .driver-age + .promo-code-container {
        flex: 1 1 50%;

        .form-control {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    .fields {
      align-items: center;
      display: flex;
      margin-right: spacer(4);

      @include media-breakpoint-down(sm) {
        margin-bottom: spacer(3);
        margin-right: 0;
      }
    }

    .promo-code-container {
      display: flex;

      .jucy-checkbox {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.alert.has-icon {
    display: flex;
    align-items: flex-start;

    > .alert-icon.fas {
        margin-top: 4px;
        font-size: 24px;
        flex: 0 0 24px;
    }
}

.alert-container {
    .alert-heading {
        white-space: nowrap;
        margin-right: 16px;
    }
}

.alert.alert-jucy-warning {
    border-width: 2px;
    font-size: $smaller-font-size;

    p {
        margin-bottom: 0;

        & + p {
            margin-bottom: $paragraph-margin-bottom;
        }
    }

    @include alert-variant(transparent, $secondary-dark, $body-color);

    &.has-icon {

        > .alert-icon.fas {
            font-size: 19px;
            color: $secondary-dark;
            flex: 0 0 34px;
        }
    }
}

.card-category-teaser-placeholder {
    @include media-breakpoint-down(sm) {
        .left,
        .center,
        .right {
            max-width: 100%;
        }
        .left {
            width: 130px;
        }
        .center {
            width: 180px;
        }
        .right {
            width: 260px;
        }
    }
}

.reservations-table {
    .reservation-summary {
        min-height: 140px;
        position: relative;

        .loader-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .rt-th {
        text-align: left;
    }
}

@keyframes loader-primary {
  20%,
  35% {
    background-color: $primary;
  }
}

@keyframes loader-seconday {
  50% {
    background-color: $secondary;
  }
}

@keyframes loader-alt {
  50% {
    background-color: $orange;
  }
}

@keyframes hour-glass-rotate {
  0% {
    transform: rotate(0deg);
  }

  25%,
  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes hour-glass-sand {
  0%,
  100% {
    border-radius: 5%;
    transform: translate(35%, 20%) rotate(45deg);
  }

  15% {
    border-radius: 40%;
    transform: translate(0%, 35%) rotate(-65deg) scale(1.5, 1.5);
  }

  25% {
    border-radius: 20%;
    transform: translate(-15%, 35%) rotate(-90deg) scale(1.2, 1.2);
  }

  50% {
    border-radius: 5%;
    transform: translate(0%, 0%) rotate(-90deg) scale(1, 1);
    opacity: 1;
  }

  50.01%,
  64.99% {
    opacity: 0;
  }

  65% {
    border-radius: 35%;
    transform: translate(20%, 50%) rotate(30deg);
    opacity: 1;
  }

  75% {
    border-radius: 10%;
    transform: translate(35%, 40%) rotate(45deg);
  }
}

@mixin loader-primary($size: 17px, $spacing: 5px, $delay: 0.7) {
  @include loader-variant('primary', $size, $spacing, $delay);
}

@mixin loader-secondary($size: 17px, $spacing: 5px, $delay: 0.7) {
  @include loader-variant('secondary', $size, $spacing, $delay);
}

@mixin loader-alt($size: 17px, $spacing: 5px, $delay: 0.7) {
  @include loader-variant('alt', $size, $spacing, $delay);
}

@mixin loader-variant($variant: 'primary', $size: 17px, $spacing: 5px, $delay: 0.7) {
  $animation-name: loader-primary;
  $background-color: $primary;

  @if $variant== 'secondary' {
    $animation-name: loader-seconday;
    $background-color: $secondary;
  }

  @if $variant== 'alt' {
    $animation-name: loader-alt;
    $background-color: $orange;
  }

  margin: auto;
  position: relative;
  text-indent: -9999em;

  &,
  &::before,
  &::after {
    display: block;
    //background-color: rgba($background-color, 0.4);
    height: $size;
    width: $size;
    animation-name: $animation-name;
    animation-duration: ($delay * 2.5) + s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    border-radius: 50%;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
  }

  &::before {
    animation-delay: ($delay * 0.5) + s;
    left: -($size + $spacing);
  }

  & {
    animation-delay: ($delay * 1) + s;
  }

  &::after {
    animation-delay: ($delay * 1.5) + s;
    left: ($size + $spacing);
  }
}

@mixin loader-container($size: 17px, $spacing: 5px, $delay: 0.7) {
  min-width: ($size * 3) + ($spacing * 2);
  text-align: center;

  &.inline {
    display: inline-block;
  }

  .loader {
    &.primary {
      @include loader-variant('primary', $size, $spacing, $delay);
    }

    &.secondary {
      @include loader-variant('secondary', $size, $spacing, $delay);
    }

    &.alt {
      @include loader-variant('alt', $size, $spacing, $delay);
    }
  }
}

@mixin hourglass($height: 36px, $border-color: $secondary, $sand-color: $yellow) {
  height: $height;
  width: math.div($height, 0.83);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; // position: relative;
  animation: hour-glass-rotate 5s ease-in-out infinite;

  i {
    z-index: 2;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    color: $border-color;
    font-size: $height;
  }

  .cup {
    height: $height*0.5;
    width: math.div($height, 1.33);
    border-radius: 50% 50% 0 0/ 100% 100% 0 0;
    overflow: hidden;

    &.top {
      transform: rotate(180deg);
    }

    &.bottom {
      &::before {
        animation-delay: 0s;
      }
    }

    &::before {
      content: ' ';
      display: block;
      background: $sand-color;
      height: 150%;
      width: 150%;
      transform-origin: 0% 0%;
      animation: hour-glass-sand 5s linear infinite -2.5s;
    }
  }
}

.loader-container {
  @include loader-container;

  &-small {
    @include loader-container(12px, 2px);
  }
}

.loader-hour-glass-container {
  width: 110px;
  border-radius: 4px;
  border: solid 2px $gray-400;
  position: relative;

  .hour-glass-wrap {
    position: absolute;
    top: 17px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .text-wrap {
    padding-top: 60px;
    line-height: 1.36;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    padding-bottom: 17px;
  }

  .hour-glass {
    @include hourglass();
  }
}

.loader-spinner{

}
.loader {
    &-hour-glass {
        @include hourglass();
        @each $name, $color in $theme-colors {
            &.#{$name} {
                @include hourglass-color($color, $primary);
            }
        }
    }

    &-dots {
        @include loader-dots();
        @each $name, $color in $theme-colors {
            &.#{$name} {
                @include loader-dots-color($name);
            }
        }
    }

    &-spinner {
        @each $name, $color in $theme-colors {
            &.#{$name} {
                @include spinner-loader( lighten($color,20%), $color);
            }
        }
    }
}

@each $name, $color in $theme-colors {
    @keyframes loader-dots-#{$name} {
        20%,
        35% {
            background-color: $color;
        }
    }
}

@keyframes hour-glass-rotate {
    0% {
        transform: rotate3d(0, 0, 0, 0);
    }
    25%,
    50% {
        transform: rotate3d(0, 0, 1, 180deg);
    }
    75% {
        transform: rotate3d(0, 0, 1, 360deg);
    }
    100% {
        transform: rotate3d(0, 0, 1, 360deg);
    }
}

@keyframes hour-glass-sand {
    0%,
    100% {
        border-radius: 5%;
        transform: translate(35%, 20%) rotate3d(0, 0, 1, 45deg);
    }
    15% {
        border-radius: 40%;
        transform: translate(0%, 35%) rotate3d(0, 0, 1, -65deg) scale(1.5, 1.5);
    }
    25% {
        border-radius: 20%;
        transform: translate(-15%, 35%) rotate3d(0, 0, 1, -90deg) scale(1.2, 1.2);
    }
    50% {
        border-radius: 5%;
        opacity: 1;
        transform: translate(0%, 0%) rotate3d(0, 0, 1, -90deg) scale(1, 1);
    }
    50.01%,
    64.99% {
        opacity: 0;
    }
    65% {
        border-radius: 35%;
        opacity: 1;
        transform: translate(20%, 50%) rotate3d(0, 0, 1, 30deg);
    }
    75% {
        border-radius: 10%;
        transform: translate(35%, 40%) rotate3d(0, 0, 1, 45deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate3d(0, 0, 0, 0);
    }
    100% {
        transform: rotate3d(0, 0, 1, 360deg);
    }
}

.page-loading-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .page-loader {
        margin: auto;

        i {
            color: $secondary;
        }
    }
}
