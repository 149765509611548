.placeholder-container {
    overflow: hidden;
    position: relative;

    &::before {
        animation: phAnimation 1.5s linear infinite;
        background: linear-gradient(to right, rgba($white, 0) 46%, rgba($white, 0.35) 50%, rgba($white, 0) 54%) 50% 50%;
        bottom: 0;
        content: ' ';
        left: 50%;
        margin-left: -250%;
        position: absolute;
        right: 0;
        top: 0;
        width: 500%;
        z-index: 1;
    }

    .placeholder {
        background-color: $gray-400;
        min-height: 10px;

        &.lg {
            height: 20px;

            &.icon-radio {
                align-items: center;
                display: flex;
                height: $float-label-input-height;
                padding: $input-padding-y $input-padding-x;
                width: 220px;

                &::after {
                    background: $white;
                    border-radius: 100%;
                    content: ' ';
                    display: block;
                    margin-left: auto;
                    width: 1rem;
                    height: 1rem;
                }

                &::before {
                    background: $white;
                    content: ' ';
                    display: block;
                    height: 20px;
                    width: 80%;
                }
            }

            &.form-field {
                height: $float-label-input-height;
                padding: $float-label-input-padding-y $float-label-input-padding-x;
                flex: 1 1;
                align-items: center;
                display: flex;

                &::before {
                    background: $white;
                    content: ' ';
                    display: block;
                    height: 16px;
                    width: 80%;
                }
            }

            &.btn {
                align-items: center;
                display: flex;
                flex: 0 0 165px;
                height: 64px;
                width: 165px;

                &::before {
                    background: $white;
                    content: ' ';
                    display: block;
                    height: 20px;
                    margin: auto;
                    width: 80%;
                }
            }
        }
    }
}

.ph-item {
    &::before {
        animation: phAnimation 1.5s linear infinite;
    }
}

.booking-widget {
    &.large {
        .placeholder-container {
            width: 36rem;
        }
    }

    &.horizontal {
        .placeholder-container {
            align-items: stretch;
            display: flex;
            flex-direction: row;
            width: 49rem;

            .fields {
                flex: 1 1 100%;
            }

            .actions {
                display: flex;
                flex: 0 0 0;
                flex-direction: column;
                margin-left: spacer(4);

                .btn {
                    margin-top: auto;
                }

                > * {
                    align-self: end;
                    display: flex;
                    flex: 1 1 100%;
                }
            }
        }
    }
}

.ph-item {
    border: none;
    padding: 0;

    .placeholder {
        background-color: $ph-color;

        &.btn {
            align-items: center;
            display: flex;
            height: 32px;
            width: 140px;

            &::before {
                background: $white;
                content: ' ';
                display: block;
                margin: auto;
                width: 80%;
                height: 15px;
            }
        }
    }

    .lg {
        &.icon-radio {
            align-items: center;
            display: flex;
            flex: 0 0 220px;
            height: 64px;
            padding: spacer(3);

            &::after {
                background: $white;
                border-radius: 100%;
                content: ' ';
                display: block;
                height: 20px;
                margin-left: auto;
                width: 20px;
            }

            &::before {
                background: $white;
                content: ' ';
                display: block;
                height: 20px;
                width: 80%;
            }
        }

        &.form-field {
            flex: 0 0 271px;
            height: 64px;
            padding: spacer(3);

            &::before {
                background: $white;
                content: ' ';
                display: block;
                height: 10px;
                width: 80%;
            }
        }

        &.btn {
            flex: 0 0 198px;
            height: 64px;

            &::before {
                height: 20px;
            }
        }
    }
}
