.horizontal-links {
    .menu-toggle,
    .nav-item > a {
        color: $body-color;
        cursor: pointer;
        padding: 0.5rem 0;
        user-select: none;
    }

    .nav-item {
        align-items: center;
        display: flex;

        &:not(:last-child) {
            margin-right: $list-inline-padding;

            &::after {
                color: $dropdown-divider-bg;
                content: ' | ';
                display: block;
                margin-left: $list-inline-padding;
            }
        }
    }
}

.list-group.jucy-list-group {
    border: none;
    border-radius: 0;
    padding: 0;

    & > .list-group-item {
        @include font-size($font-size-base * 0.875);

        background: none;
        border: none;
        border-radius: 0;
        padding: 0;

        a {
            color: $body-color;
            display: block;
            padding: ($spacer * 0.25) 0;
        }

        &.title {
            font-weight: $font-weight-ultra;
            height: 2.0625rem;
            line-height: 1.2;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
        }
    }
}
