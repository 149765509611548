%label-defaults {
  color: $gray-800;
  font-size: 0.875rem;
  margin-bottom: 0;
}

%float-label-small {
  font-size: 75%;
  height: auto;
  opacity: 1;
  padding-bottom: 0;
  padding-top: 0.25rem;
}

label {
  @extend %label-defaults;
}

.custom-control-label {
  padding-top: 3px;

  @extend %label-defaults;
}

.form-control {
  @include font-size($input-font-size);
  font-weight: $input-font-weight;

  &:focus,
  &.has-focus {
    position: relative;
    z-index: 2;
  }
}

.form-label-group {
  position: relative;

  > label {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input,
  select,
  .jucy-select {
    height: $float-label-input-height;
  }

  .jucy-select {
    & + label {
      padding-right: 27px;
    }
  }

  input,
  .jucy-select .form-control.jucy-select .jucy-select__value-container {
    padding: $float-label-input-padding-y $float-label-input-padding-x;
  }

  select {
    padding-left: ($float-label-input-padding-x - 0.25);
    padding-right: ($float-label-input-padding-x - 0.25);

    &.has-value {
      padding-top: $float-label-input-padding-y + ($float-label-input-padding-active-y - 0.5625);
    }
  }

  label {
    border: $input-border-width solid transparent;
    color: $input-color;
    cursor: text;
    display: block;
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    height: $float-label-input-height;

    /* Override default `<label>` margin */
    left: 0;
    line-height: $input-line-height;
    margin-bottom: 0;
    padding: $float-label-input-padding-y $float-label-input-padding-x;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: all 0.1s ease-in-out;
    width: 100%;
  }

  .jucy-select__placeholder {
    opacity: 0;
  }

  input,
  select {
    &::placeholder {
      opacity: 0;
    }
  }

  input:not(:placeholder-shown),
  .jucy-select.has-value .jucy-select__value-container,
  .jucy-select.is-searching .jucy-select__value-container {
    padding-top: $float-label-input-padding-y + $float-label-input-padding-active-y;
  }

  .form-control.jucy-select {
    &.has-value,
    &.is-searching {
      .jucy-select__value-container {
        padding-top: ($float-label-input-padding-y + $float-label-input-padding-active-y) - 0.3875;
      }
    }
  }

  input:not(:placeholder-shown),
  select.has-value,
  .jucy-select.has-value,
  .jucy-select.is-searching {
    ~ label {
      @extend %float-label-small;
    }

    &:focus,
    &.has-focus {
      ~ label {
        z-index: 4;
      }
    }
  }

  input:focus,
  input.has-focus,
  select:focus,
  select.has-focus,
  .jucy-select.has-focus {
    ~ label {
      z-index: 2;
    }
  }
}

.input-group {
  .form-label-group {
    &:not(:last-child) {
      .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        border-right-color: transparent;
      }
    }

    &:not(:first-child) {
      .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}


.react-tel-input.MuiInputBase-input {
  @include font-size($font-size-base);
  font-weight: $input-font-weight;
  font-family: $input-font-family;
}
