.nav-tabs {
    .nav-link {
        border-bottom-width: 0.25rem;
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 0;
        color: $gray-800;
        font-size: 0.875rem;
        font-weight: bold;
        text-transform: uppercase;
    }
}
