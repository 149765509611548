.text-jucy-green {
  color: $primary;
}

.text-jucy-purple {
  color: $secondary;
}

.font-apex-book {
  font-family: $apex-rounded-book;
}

.font-apex {
  font-family: $apex-rounded;
}

.fw-heavy {
  font-weight: $font-weight-heavy;
}

.fw-bold {
  font-weight: $font-weight-ultra;
}

.smaller {
  @include font-size(0.765625rem);
}

.text-shadow {
  text-shadow: 0 0 spacer(2) rgba($black, 0.85);
}

.text-normal {
  font-weight: normal;
  font-size: $font-size-base;
}

.text-green {
  color: $green;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  a {
    color: inherit;
  }
}
