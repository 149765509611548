.bg-jucy-green {
    background-color: $primary;
}

.bg-jucy-purple {
    background-color: $secondary;
}

.bg-light {
    background-color: $light;
}
