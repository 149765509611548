.btn {

    --#{$prefix}btn-padding-x: #{$btn-padding-x};
    --#{$prefix}btn-padding-y: #{$btn-padding-y};
    --#{$prefix}btn-font-family: #{$btn-font-family};
    @include rfs($btn-font-size, --#{$prefix}btn-font-size);
    --#{$prefix}btn-font-weight: #{$btn-font-weight};
    --#{$prefix}btn-line-height: #{$btn-line-height};
    --#{$prefix}btn-color: #{$body-color};
    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-border-width: #{$btn-border-width};
    --#{$prefix}btn-border-color: transparent;
    --#{$prefix}btn-border-radius: #{$btn-border-radius};
    --#{$prefix}btn-hover-border-color: transparent;
    --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
    --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
    --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} rgba(var(--#{$prefix}btn-focus-shadow-rgb), .5);
    // scss-docs-end btn-css-vars

    &:not(.btn-link) {
        //font-style: italic;
        //text-transform: uppercase;
    }

    &.btn-link {
        //color: $body-color;
        //font-family: $font-family-base;

        &.btn-tertiary,
        &.btn-alt {
            color: $secondary;
            font-weight: bold;
        }

        &.btn-alt {
            background: $gray-100;
        }

        &.small {
            font-size: $font-size-sm;
        }
    }

    &.btn-round {
        background: $white;
        border-radius: 50%;
        box-shadow: 0 1px 1px #00000029;
        color: $gray-800;
        min-height: 40px;
        min-width: 40px;
        padding: 0;
    }

    &.social-link {
        align-items: center;
        color: $secondary;
        display: inline-flex;
        font-size: 1.6875rem;
        justify-content: center;
    }
}

.play-btn {
    align-items: center;
    height: 96px;
    width: 96px;

    i {
        color: $white;
        font-size: 32px;
    }
}

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}
