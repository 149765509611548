@import 'config/index';
@import 'includes/index';
@import 'vendor/index';
@import 'partials/index';

#__next {
  height: 100%;
}

:root {
  --color-green: #{$green};
  --color-purple: #{$purple};
  --color-purple-dark: #{$purple-dark};
  --color-orange: #{$orange};
  --color-charcoal: #{$charcoal};
  --color-lime: #{$lime};
  --color-cobalt-blue: #{$cobalt-blue};
  --color-sky-blue: #{$sky-blue};
  --color-sea-blue: #{$sea-blue};
  --color-sand: #{$sand};
  --color-rock: #{$rock};
  --color-shell: #{$shell};
  --toastify-font-family: #{$font-family-sans-serif};
  --bs-font-sans-serif: #{$font-family-sans-serif};

  @each $color, $value in $theme-colors {
    .btn-#{$color} {
      @if $color == "light" {
        @include button-variant(
                        $value,
                        $value,
                $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
                $hover-border: shade-color($value, $btn-hover-border-shade-amount),
                $active-background: shade-color($value, $btn-active-bg-shade-amount),
                $active-border: shade-color($value, $btn-active-border-shade-amount)
        );
      } @else if $color == "dark" {
        @include button-variant(
                        $value,
                        $value,
                $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
                $hover-border: tint-color($value, $btn-hover-border-tint-amount),
                $active-background: tint-color($value, $btn-active-bg-tint-amount),
                $active-border: tint-color($value, $btn-active-border-tint-amount)
        );
      } @else {
        @include button-variant($value, $value);
      }
    }
  }

  @each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
      @include button-outline-variant($value);
    }
  }
}

[data-brand="jucy"] {
  --primary-color: var(--color-green);
  --secondary-color: var(--color-purple);
  --heading-dark: var(--color-purple-dark);
  --#{$prefix}link-color: var(--color-purple);
  --#{$prefix}link-hover-color: var(--color-purple-dark);
  --focused-form-field-box-shadow: 0 0 transparent, 0 0 0 0.25rem rgb(134 1 113 / 25%);

}

[data-brand="star"] {
  --primary-color: var(--color-sky-blue);
  --secondary-color: var(--color-sea-blue);
  --heading-dark: var(--color-cobalt-blue);
  --#{$prefix}link-color: var(--color-cobalt-blue);
  --#{$prefix}link-hover-color: var(--color-sky-blue);
  --focused-form-field-box-shadow: 0 0 transparent, 0 0 0 0.25rem rgb(11 26 45 / 25%)

}

.form-control:focus {
  border-color: var(--secondary-color);
  box-shadow: var(--focused-form-field-box-shadow);
}

.separator {
  align-items: center;
  color: $gray-400;
  display: flex;
  justify-content: center;
  text-align: center;
  width: spacer(4);
}

.alert-purple-dark {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}

.card.has-badge {
  margin-top: 24px;
}

.card-badge-top {
  text-align: left;
  position: absolute;
  top: -24px;
  left: -1px;
  right: -1px;
  border-radius: $card-border-radius $card-border-radius 0 0;
  padding: ($badge-padding-y * 2) spacer(3) ($badge-padding-y * 4) spacer(3);
  z-index: -1;
}

.card-banner-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  padding: 0 spacer(3);
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 30px;

  i.manualpayment {
    font-size: 18px;
    margin-top: -2px;
    margin-right: 5px;
  }

  &.bg-jucy-green {
    color: $white;
  }

  @include border-top-radius($card-inner-border-radius);
}

.content-container {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    overflow: hidden;

    > .container {
      flex: 1 1;
      display: flex;
    }
  }
}

//.main-content,
//.booking-summary {
//    padding-top: spacer(4);
//}

//.main-content {
//    z-index: 1;
//    @include media-breakpoint-up(md) {
//    padding-right: $grid-gutter-width;
//    }
//}
//
//.booking-summary {
//    z-index: 2;
//}
a, .btn-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.side-bar {
  background: $light;
  position: relative;
  @include media-breakpoint-up(lg) {
    &::after {
      content: '';
      display: block;
      width: 300%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: $light;
      z-index: -1;
    }
  }

  //@include media-breakpoint-down(md) {
  //    position: fixed;
  //    top: $site-header-height-sm;
  //    transform: translate3d(0, 100%, 0);
  //    transition: transform $default-transition-speed $default-transition-timing-function;
  //    width: 100%;
  //    z-index: 1;
  //    left: 0;
  //    right: 0;
  //    bottom: 0;
  //    overflow-y: scroll;
  //    .booking-summary {
  //        overflow: hidden auto;
  //    }
  //
  //    .show-summary & {
  //        transform: translate3d(0, 0, 0);
  //    }
  //}
}

.line-height-1 {
  line-height: 1;
}

.line-height-base {
  line-height: $line-height-base;
}

.border-1 {
  border-width: 1px;
}

button:focus {
  outline: none;
}

.form-control.has-focus {
  box-shadow: 0 0 transparent, 0 0 0 0.25rem rgb(134 1 113 / 25%);
}

.small, small {
  font-size: .75rem;
}
