.excess-specs {
  color: var(--text-color);
  line-height: 1;
  max-width: 100%;
}

[data-brand=jucy] {
  --primary-color: var(--color-green);
  --secondary-color: var(--color-purple);
  --text-color: var(--color-charcoal);
  --even-rows: #e7e7e7;
  --odd-rows: #fff;
  --primary-bg-light: #d1e6aa;
}

[data-brand=star] {
  --primary-color: var(--color-sky-blue);
  --secondary-color: var(--color-sea-blue);
  --text-color: var(--color-sky-blue);
  --even-rows: #bcc7cf;
  --odd-rows: #e9edef;
  --primary-bg-light: #fff;
}

.excess-specs .excess-table-wrap {
  overflow: auto;
  scroll-snap-type: both mandatory;
}

.excess-specs table {
  border-collapse: collapse;
  border: 0;
  width: 100%;
}

.excess-specs th, .excess-specs td {
  box-sizing: border-box;
  background-clip: padding-box;
  scroll-snap-align: start;
  padding: 0.5em;
  height: 2.3em;
}

.excess-specs th.product-label {
  font-size: 1.2em;
  white-space: nowrap;
  text-align: center;
}

.excess-specs th.spec-label {
  text-transform: uppercase;
  text-align: left;
}

.excess-specs .spec-label small {
  font-size: 0.6em;
}

.excess-specs thead {
  z-index: 1000;
  position: relative;
}

.excess-specs thead th {
  position: sticky;
  top: 0;
  border-top: 0;
  background-clip: padding-box;
  font-weight: bold;
  background: var(--primary-bg-light);
}

.excess-specs thead th.pin {
  left: 0;
  z-index: 1001;
  border-left: 0;
}

.excess-specs tbody {
  z-index: 10;
  position: relative;
}

.excess-specs tbody th {
  background-clip: padding-box;
  border-left: 0;
  position: sticky;
  left: 0;
}

.excess-specs tbody tr:nth-child(odd) {
  background: var(--odd-rows);
}

.excess-specs tbody tr:nth-child(odd) th[role=cell] {
  background: var(--odd-rows);
}

.excess-specs tbody tr:nth-child(even) {
  background: var(--even-rows);
}

.excess-specs tbody tr:nth-child(even) th[role=cell] {
  background: var(--even-rows);
}

.excess-specs .spec-value {
  text-align: center;
  font-weight: bold;
}

.excess-specs small.footer {
  font-size: 0.7em;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  text-align: right;
  padding-top: 1em;
}
@media (max-width: 550px) {
  .excess-specs {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .excess-specs {
    font-size: 12px;
  }
}
