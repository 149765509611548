.border-top-lg {
  border-top-width: 32px;
  border-left-width: 0;
  border-bottom-width: 0;
  border-eight-width: 0;
  border-style: solid;
}

.border-purple {
  border-color: $secondary;
}
