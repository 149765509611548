.card.card-jucy {
    padding-top: 0.5rem;

    .sub-title {
        float: right;

        strong {
            font-weight: $font-weight-ultra;
        }
    }
}

a.hover-img {
    img {
        transition: opacity $default-transition-speed $default-transition-timing-function,
        box-shadow $default-transition-speed $default-transition-timing-function;
        opacity: 0.9;
    }

    &:hover {
        img {
            opacity: 1;
            box-shadow: $secondary-drop-shadow;
        }
    }
}
